import React, { FC, useMemo, useState } from "react";
import { AppWithStyles, appWithStyles, useMediaQuery } from "@shared/material";
import { styles } from "./course-details.styles";
import baseTheme from "@shared/theme/base-theme";
import { BreakpointLayout } from "@shared/enum/breakpoint";
import { Box } from "@mui/material";
import { useParams } from "react-router-dom";
import { SectionHeader } from "@shared/components/section-header";
import { CourseDetailsViewModel } from "@ui/private/courses/course-details/course-details.vm";
import { StyledBreadcrumb } from "@shared/components/styled-breadcrumb";
import { t } from "@lingui/macro";
import { ROUTES } from "../../../../routing/routes";
import { NavItem } from "@shared/types/nav-item";
import { appInject } from "@core/di/utils";
import { ILocalesViewModel } from "@shared/interfaces/locales-service.interface";
import { DI_TOKENS } from "@shared/constants/di";
import { appReaction } from "@core/state-management/utils";

export interface CourseDetailsProps extends AppWithStyles<typeof styles> {}

const CourseDetails: FC<CourseDetailsProps> = ({
  classes,
}: CourseDetailsProps) => {
  const [_, render] = useState(0);

  const isMobile = useMediaQuery(() =>
    baseTheme.breakpoints.down(BreakpointLayout.LAPTOP),
  );

  const localesViewModel = appInject<ILocalesViewModel>(
    DI_TOKENS.localesViewModel,
  );

  appReaction(
    () => localesViewModel.locale,
    () => render(_ + 1),
  );
  const { courseId, blockId } = useParams();

  const $vm = useMemo(
    () =>
      new CourseDetailsViewModel().setBlockIdAndCourseId(
        blockId || "",
        courseId || "",
      ),
    [],
  );

  const breadcrumbs: NavItem[] = [
    { label: t`Video courses`, path: ROUTES.courses },
    { label: $vm.currentBlockTitle || "", path: ROUTES.blockDetails(blockId) },
    { label: $vm.currentLessonsTitle || "", path: "" },
  ];

  const currentLessonsUrl: string =
    $vm.currentLessonsUrl[localesViewModel.locale];

  return (
    <Box>
      <Box
        sx={{
          paddingLeft: "135px",
          paddingRight: "135px",
          [baseTheme.breakpoints.down(BreakpointLayout.LAPTOP)]: {
            paddingLeft: "24px",
            paddingRight: "24px",
          },
        }}
      >
        <StyledBreadcrumb paths={breadcrumbs} />
      </Box>
      <SectionHeader title={$vm?.currentLessonsTitle || ""} />
      <Box
        sx={{
          paddingLeft: "135px",
          paddingRight: "135px",
          [baseTheme.breakpoints.down(BreakpointLayout.LAPTOP)]: {
            paddingLeft: "24px",
            paddingRight: "24px",
          },
        }}
      >
        <Box
          sx={{
            padding: "20px 0px",
            width: "100%",
            height: "calc(100vh - 367px)",
            minHeight: "700px",
            [baseTheme.breakpoints.down(BreakpointLayout.LAPTOP)]: {
              minHeight: "unset",
              height: "calc(100vh - 268px)",
            },
          }}
        >
          {isMobile ? (
            <iframe
              src={`https://docs.google.com/viewer?url=${encodeURIComponent(currentLessonsUrl)}&embedded=true`}
              style={{ width: "100%", height: "100%", border: "none" }}
              title="PDF Viewer"
            />
          ) : (
            <object
              data={currentLessonsUrl}
              type="application/pdf"
              width="100%"
              height="100%"
              title="PDF Preview"
            ></object>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default appWithStyles(styles)(CourseDetails);
