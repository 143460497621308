import { CourseListModel } from "@shared/models/course-list.model";
import { BlockListModel } from "@shared/models/block-list.model";
import { Locale } from "@shared/enum/locale.enum";

const titles: string[] = ["Gazer S5 overview"];

const sectionTitles: string[] = ["Gazer S5 overview"];

export const testLessons: CourseListModel[] = titles.map(
  (title, index) =>
    new CourseListModel({
      id: String(index),
      title: title,
      url: {
        [Locale.English]: `${window.location.origin}/assets/courses/${encodeURIComponent(title)}/en.pdf`,
        [Locale.Russian]: `${window.location.origin}/assets/courses/${encodeURIComponent(title)}/en.pdf`,
        [Locale.Ukrainian]: `${window.location.origin}/assets/courses/${encodeURIComponent(title)}/ua.pdf`,
      },
    }),
);

export const testBlocks: BlockListModel[] = sectionTitles.map(
  (title, index) =>
    new BlockListModel({
      id: String(index),
      title: title,
      courses: testLessons.slice(0, 5),
    }),
);
