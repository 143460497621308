import React, { FC, useState } from "react";
import {
  AppWithStyles,
  appWithStyles,
  Box,
  useMediaQuery,
} from "@shared/material";
import { styles } from "./course.styles";
import { CourseListModel } from "@shared/models/course-list.model";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../../../routing/routes";
import { BlockListModel } from "@shared/models/block-list.model";
import { StyledIconButton } from "@shared/components/buttons/styled-icon-button";
import { EditIcon } from "@shared/images/icons/edit-icon";
import { DeleteIcon } from "@shared/images/icons/delete-icon";
import baseTheme from "@shared/theme/base-theme";
import { appInject } from "@core/di/utils";
import { IAuthService } from "@shared/interfaces/auth-service.interface";
import { DI_TOKENS } from "@shared/constants/di";
import { BreakpointLayout } from "@shared/enum/breakpoint";
import { ILocalesViewModel } from "@shared/interfaces/locales-service.interface";
import { appReaction } from "@core/state-management/utils";

export interface CourseProps extends AppWithStyles<typeof styles> {
  course: CourseListModel;
  block: BlockListModel;
}

const Course: FC<CourseProps> = ({ classes, course, block }: CourseProps) => {
  const [_, render] = useState(0);
  const navigate = useNavigate();
  const authService = appInject<IAuthService>(DI_TOKENS.authService);
  const isMobile = useMediaQuery(() =>
    baseTheme.breakpoints.down(BreakpointLayout.LAPTOP),
  );

  const localesViewModel = appInject<ILocalesViewModel>(
    DI_TOKENS.localesViewModel,
  );

  appReaction(
    () => localesViewModel.locale,
    () => render(_ + 1),
  );

  return (
    <Box
      sx={{
        height: "233px",
        width: "373px",
        backgroundColor: baseTheme.palette.background.paper,
        boxShadow: "inset -180px 0px 106px -75px #101216",
        borderRadius: "10px",
        cursor: "pointer",
        position: "relative",
        [baseTheme.breakpoints.down(BreakpointLayout.LAPTOP)]: {
          width: "336px",
        },
      }}
      onClick={() =>
        navigate(ROUTES.courseDetails(block.asJson.id, course.asJson.id))
      }
    >
      {authService.isAdmin ? (
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            position: "absolute",
            top: "10px",
            right: "10px",
          }}
        >
          <StyledIconButton
            icon={<EditIcon />}
            onClick={(e: any) => {
              e.stopPropagation();
            }}
          />
          <StyledIconButton
            icon={<DeleteIcon />}
            onClick={(e: any) => {
              e.stopPropagation();
            }}
            color={"secondary"}
          />
        </Box>
      ) : (
        <></>
      )}
      <Box
        sx={{
          width: "100%",
          height: "164px",
          borderRadius: "10px",
          boxShadow: "inset -180px 0px 106px -75px #101216",
          overflow: "hidden",
          position: "relative",
          cursor: "pointer",
        }}
      >
        <Box
          onClick={() =>
            navigate(ROUTES.courseDetails(block.asJson.id, course.asJson.id))
          }
          sx={{
            position: "absolute",
            width: "100%",
            height: "100%",
            left: "0px",
            top: "0px",
            cursor: "pointer",
          }}
        ></Box>
        <iframe
          src={course.asJson.url[localesViewModel.locale]}
          width={isMobile ? "336" : "373"}
          height="164"
          className={classes.pdfPreview}
          title="PDF Preview"
        ></iframe>
      </Box>
      <div className={classes.title}>{course.asJson.title}</div>
    </Box>
  );
};

export default appWithStyles(styles)(Course);
