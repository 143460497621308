import { ICarsService } from "@shared/interfaces/cars-service.interface";
import { appInject, appInjectable } from "@core/di/utils";
import { IHttpClientService } from "@shared/interfaces/http-client-service.interface";
import { DI_TOKENS } from "@shared/constants/di";
import { GetCarsModelsListRequestDto } from "@shared/dto/cars/get-cars-models-list-request.dto";
import {
  CarModelListDto,
  CarModelListModel,
} from "@shared/models/cars/car-model-list.model";
import {
  CarModificationListDto,
  CarModificationListModel,
} from "@shared/models/cars/car-modification-list.model";
import { GetCarsModificationsListRequestDto } from "@shared/dto/cars/get-cars-modifications-list-request.dto";
import { IConfigService } from "@shared/interfaces/config-service.interface";
import { GetCarsBrandsListRequestDto } from "@shared/dto/cars/get-cars-brands-list-request.dto";
import {
  CarBrandListDto,
  CarBrandListModel,
} from "@shared/models/cars/car-brand-list.dto";
import {
  CarInstallationDto,
  CarInstallationModel,
} from "@shared/models/cars/car-installation.dto";
import { GetCarDetailsRequestDto } from "@shared/dto/cars/get-car-installation-request.dto";
import {
  CarLinksDto,
  CarLinksModel,
} from "@shared/models/cars/car-links.model";
import {
  CanFunctionDto,
  CanFunctionModel,
} from "@shared/models/cars/can-function.model";
import { Version } from "@shared/enum/version.enum";
import { controlsFunctionsList } from "@shared/constants/controls-functions-list";
import { notableFunctionsList } from "@shared/constants/notable-functions-list";
import { Locale } from "@shared/enum/locale.enum";

@appInjectable()
export class CarsService implements ICarsService {
  private configService = appInject<IConfigService>(DI_TOKENS.configService);
  private httpClient = appInject<IHttpClientService>(
    DI_TOKENS.httpClientService,
  );
  private _baseURL: string;

  constructor() {
    this._baseURL = this.configService.apiUrl;
  }

  async getBrandsList(
    options: GetCarsBrandsListRequestDto,
  ): Promise<CarBrandListModel[]> {
    const { data } = await this.httpClient.get<CarBrandListDto[]>(
      `/cars/${options.version}/brands`,
      {
        params: options,
        baseURL: this._baseURL,
      },
    );
    if (!data) return [];
    return data.map((item) => new CarBrandListModel(item));
  }

  async getModelsList(
    options: GetCarsModelsListRequestDto,
  ): Promise<CarModelListModel[]> {
    const { data } = await this.httpClient.get<CarModelListDto[]>(
      `/cars/${options.version}/models`,
      {
        params: options,
        baseURL: this._baseURL,
      },
    );
    if (!data) return [];
    return data.map((item) => new CarModelListModel(item));
  }

  async getModificationsList(
    options: GetCarsModificationsListRequestDto,
  ): Promise<CarModificationListModel[]> {
    const { data } = await this.httpClient.get<CarModificationListDto[]>(
      `/cars/${options.version}/modifications`,
      {
        params: options,
        baseURL: this._baseURL,
      },
    );
    if (!data) return [];
    return data.map((item) => new CarModificationListModel(item));
  }

  async getCarInstallation(
    options: GetCarDetailsRequestDto,
  ): Promise<CarInstallationModel> {
    const { data } = await this.httpClient.get<CarInstallationDto>(
      `/cars/${options.version}/installation`,
      {
        params: options,
        baseURL: this._baseURL,
      },
    );
    return new CarInstallationModel(data);
  }

  async getCarLinks(options: GetCarDetailsRequestDto): Promise<CarLinksModel> {
    const { data } = await this.httpClient.get<CarLinksDto>("/cars/V1/links", {
      params: options,
      baseURL: this._baseURL,
    });
    return new CarLinksModel(data);
  }

  async getInstallerLinks(): Promise<{ macos: string; android: string }> {
    const { data } = await this.httpClient.get<{
      macos: string;
      android: string;
    }>("/cars/installer-link", {
      baseURL: this._baseURL,
    });
    return {
      macos: data.macos,
      android: data.android,
    };
  }

  async getCanFunctions(
    options: GetCarDetailsRequestDto,
    locale: Locale,
  ): Promise<Array<CanFunctionModel>> {
    const { data } = await this.httpClient.get<Array<CanFunctionDto>>(
      `/cars/${options.version}/can-functions`,
      {
        params: options,
        baseURL: this._baseURL,
      },
    );
    return data
      .sort((a, b) => {
        return a.sortOrderNumber - b.sortOrderNumber;
      })
      .map((item) => {
        if (
          options.version === Version.V1 &&
          controlsFunctionsList.includes(item.functionName.en)
        ) {
          item.executionCommand = item.readableParams;
          item.readableParams = "";
        }

        if (
          options.version === Version.V1 &&
          item.functionName.en === "Control"
        ) {
          return null;
        }

        if (item.notes.uk === "-") {
          item.notes.uk = "";
        }
        if (item.notes.en === "-") {
          item.notes.en = "";
        }
        if (item.notes.ru === "-") {
          item.notes.ru = "";
        }

        if (
          options.version === Version.V2 &&
          notableFunctionsList.includes(item.functionName.en)
        ) {
          item.executionCommand = item.notes[locale];
          item.notes.uk = "";
          item.notes.en = "";
          item.notes.ru = "";
        }

        return item;
      })
      .filter((item) => Boolean(item))
      .map((item) => new CanFunctionModel(item as CanFunctionDto));
  }
}
