import { BaseModel } from "@core/models/base-model";
import { Locale } from "@shared/enum/locale.enum";

export interface CourseListDto {
  id: string;
  title: string;
  url: Record<Locale, string>;
}

export class CourseListModel extends BaseModel<CourseListDto> {
  private id?: string;
  private title?: string;
  private url?: Record<Locale, string>;

  constructor(data: CourseListDto) {
    super();

    this.update(data);
  }

  get asJson(): CourseListDto {
    return {
      id: this.id || "",
      title: this.title || "",
      url: this.url || {
        [Locale.English]: "",
        [Locale.Russian]: "",
        [Locale.Ukrainian]: "",
      },
    };
  }
}
